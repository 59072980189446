import React from 'react'
import styled from 'styled-components'
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl'

import Layout from '../components/Layout'
import SEO from '../components/SEO'

// ================================================================================================

const NotFoundPage = ({ intl }) => (
  <Layout>
    <SEO title={'404'} />
    <Title>
      <FormattedMessage id="pageNotFound" />
    </Title>
  </Layout>
)

// ================================================================================================

const Title = styled.h1`
  font-size: 2rem;
`

// ================================================================================================

export default injectIntl(NotFoundPage)
